import { PopGames } from "../list/PopGames";
import Link from "next/link";

function myLoopPopular() {
  var myArray = [];
  var i;
  for (i = 0; i < PopGames.length; i++) {
    myArray[i] = (
      <li key={i} className="para description">
        <Link href={PopGames[i].path} prefetch={false}>
          <a title={PopGames[i].name}>{PopGames[i].name}</a>
        </Link>
      </li>
    );
  }
  return myArray;
}
export default function PopularGames() {
  return (
    <div>
      <h2 className="contant name">Most popular games of the week </h2>
      <ul className="gamelist">{myLoopPopular()}</ul>

      <h2 className="contant name">Our Other Game Portal </h2>
      <ul className="gamelist">
      <li className="para description">
        <Link href={"https://cutedressup.com/"} prefetch={false}>
          <a title={"cutedressup.com"}>{"Cutedressup"}</a>
        </Link>
      </li>
      <li className="para description">
        <Link href={"https://bestgamespot.com/"} prefetch={false}>
          <a title={"bestgamespot.com"}>{"Bestgamespot"}</a>
        </Link>
      </li>
      <li className="para description">
        <Link href={"https://colorifyme.com/"} prefetch={false}>
          <a title={"colorifyme.com"}>{"Colorifyme"}</a>
        </Link>
      </li>
      <li className="para description">
        <Link  href={"https://freearcadegames.net/"} prefetch={false}>
        <a target="_blank" title={"Free Arcade Games"}>{"Free Arcade Games"}</a>
        </Link>
      </li>
      <li className="para description">
        <Link  href={"https://subwaysurfersonline.com/"} prefetch={false}>
        <a target="_blank" title={"Subway Surfers Online"}>{"Subway Surfers Online"}</a>
        </Link>
      </li>
      
      </ul>
    </div>
  );
}
