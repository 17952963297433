export const PopGames = [
  {
    name:"BFFs Trendy Squad Fashion",
    path:"/game/bffs-trendy-squad-fashion/",
  },
  {
    name:"My Romantic Valentine Story",
    path:"/game/my-romantic-valentine-story/",
  },
  {
    name:"Angela Perfect Valentine",
    path:"/game/angela-perfect-valentine/",
  },
  {
    name:"My Best FF Outfits",
    path:"/game/my-best-ff-outfits/",
  },
  {
    name:"Princesses As Ancient Warriors",
    path:"/game/princesses-as-ancient-warriors/",
  },
  {
    name:"Celebrity Signature Styles",
    path:"/game/celebrity-signature-styles",
  },{
    name:"BFFs Trendy Squad Fashion",
    path:"/game/bffs-trendy-squad-fashion/",
  },
  {
    name:"My Romantic Valentine Story",
    path:"/game/my-romantic-valentine-story/",
  },
  {
    name:"Angela Perfect Valentine",
    path:"/game/angela-perfect-valentine/",
  },
  {
    name:"My Best FF Outfits",
    path:"/game/my-best-ff-outfits/",
  },
  {
    name:"Princesses As Ancient Warriors",
    path:"/game/princesses-as-ancient-warriors/",
  },
];
